<template>
  <div>
    <div ref="fullText" class="full-text">{{ text }}__</div>
    <div class="animated-text" :style="{ height: textHeight + 'px' }">
      <span>{{ displayedText }}</span>
      <span class="cursor" :class="{ blink: blinking }">_</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    fontsize: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      displayedText: "",
      cursorInterval: null,
      isAnimating: false,
      blinking: true,
      textHeight: 0,
      observer: null,
      hasBeenVisible: false
    };
  },
  methods: {
    typeText() {
      let index = 0;
      const typingSpeed = 130; // Speed at which letters appear
      const textInterval = setInterval(() => {
        if (index < this.text.length) {
          this.displayedText += this.text.charAt(index);
          index++;
        } else {
          clearInterval(textInterval);
          this.isAnimating = false;
        }
      }, typingSpeed);
    },
    toggleCursorBlink() {
      this.blinking = !this.blinking;
    },
    measureTextHeight() {
      this.textHeight = this.$refs.fullText.offsetHeight;
    },
    handleIntersection(entries) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        if (!this.hasBeenVisible) {
          this.hasBeenVisible = true;
          this.resetAnimation();
          this.typeText();
          this.isAnimating = true;
          this.cursorInterval = setInterval(this.toggleCursorBlink, 500); // Cursor blink speed
        } else {
          this.displayedText = this.text;
        }
        this.observer.unobserve(this.$refs.fullText); // Stop observing once the animation is triggered
      } else if (this.hasBeenVisible) {
        this.displayedText = this.text;
      }
    },
    resetAnimation() {
      this.displayedText = "";
    //  clearInterval(this.cursorInterval);
    //  this.cursorInterval = null;
    },
    createObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
      this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0.1 });
      this.observer.observe(this.$refs.fullText);
    }
  },
  mounted() {
    this.$nextTick(this.measureTextHeight);
    this.createObserver();
  },
  beforeUnmount() {
    //clearInterval(this.cursorInterval);
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style>
.full-text {
  visibility: hidden;
  position: absolute;
  font-size: 65px;
  font-weight: bold;
  min-width: 50%;
  max-width: 70%;
}

.animated-text {
  min-width: 50%;
  max-width: 70%;
  font-weight: bold;
  font-size: 80px;
  display: inline-block;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 250px;
}

.cursor.blink {
  opacity: 1;
}

.cursor {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>