<template>
  <img :src="require(`@/assets/images/workspace/stock_workspace.png`)" :alt="workspaceASS" />
</template>
<script>
export default {
  data() {
    return {
      workspaceImage: require('@/assets/images/workspace/stock_workspace.png'),
      workspaceImageAlt: 'Workspace image'
    };
  },
};
</script>
<style>
img {
  padding-left: 40px;
}
</style>