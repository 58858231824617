<template>
  <div class="team-gallery">
    <p>MEET THE CREW</p>
    <div class="team-members">
      <div class="team-member" v-for="(member, index) in team" :key="index">
        <div class="member-image-wrapper" @mouseover="hover = index" @mouseleave="hover = null">
          <img :src="hover === index ? require(`@/assets/images/members/${member.altPhoto}`) : require(`@/assets/images/members/${member.photo}`)" :alt="member.firstname" />
        </div>
        <div>
          <p class="member-description>">
            <span class="member-description-firstname">{{ member.firstname }} {{ member.lastname.charAt(0) }}. </span>
            <span class="member-description-role">{{ member.role }}</span></p>
          <p>{{ member.description }}</p>
          <p>{{ member.funfact }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamGallery',
  data() {
    return {
      hover: null,
      team: [
        { firstname: 'Gina', lastname: 'Nottenkämper', role: 'Developer', description: 'Im Controlling vereint sie ihre Leidenschaft für Zahlen mit kreativen Lösungen. Sie denkt um die Ecke und entdeckt Ansätze, die das Potenzial eines jeden Prozesses voll entfalten. Für sie sind Zahlen nicht nur Daten, sondern der Schlüssel zu neuen Möglichkeiten.', funfact: '', altPhoto: 'ginan.png', photo: 'herz.png' },
        { firstname: 'Jonas', lastname: 'Kloos', role: 'Developer', description: 'Java war seine erste große Liebe und bis heute kann nichts die beiden entzweien. Außerdem findet er Vergnügen in Komplexität, verteilten Systemen und Organisation von Daten. Aus dem Existierenden versucht er stets das Maximum herauszuholen', funfact: '', altPhoto: 'jonask.png', photo: 'pik.png'},
        { firstname: 'Philipp', lastname: 'Malskorn', role: 'Developer', description: 'The only good system is a Design System: Schwärmt für Konsistenz und eine benutzerfreundliche Welt. Albi, wie man ihn gerne nennt, ist nicht nur im Alltag Pragmatiker und Minimalist, sondern auch bei der Entwicklung effektiver Frontends.', funfact: '', altPhoto: 'philippm.png', photo: 'karo.png'},
        { firstname: 'Jonas', lastname: 'Schüppen', role: 'Developer', description: 'Brennt für pragmatische Lösungen in komplexen Systemen. Ist am glücklichsten, wenn andere glücklich sein können. Hat mal was mit Programmieren gemacht. Unternehmertum ist daher so ziemlich genau sein Ding.', funfact: '', altPhoto: 'jonass.png', photo: 'kreuz.png' },
      ]
    };
  }
};
</script>

<style scoped>
.team-gallery {
  padding: 20px;
}

.team-members {
  display: flex;
  gap: 30px; /* Space between the items */
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory; /* Enable snapping (optional) */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.team-member {
  min-width: 200px; /* Minimum width for each member */
  max-width: 350px; /* Maximum width for each member */
  margin: 10px;
  scroll-snap-align: start; /* Align each item at start (optional) */
}

.member-description-role{
  color: dimgray;
  font-size: calc(1em - 2px);
}

.member-description-firstname{
  font-weight: bold;
  font-size: calc(1em + 2px);
}

.member-image-wrapper {
  cursor: pointer;
}

.member-image-wrapper img {
  width: 100%; /* Ensure image takes the full width of the container */
  border-radius: 12px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.member-image-wrapper:hover img {
  transform: scale(1.02);
  opacity: 0.8;
}
</style>