<template>
  <p>UNSERE KOMPETENZEN</p>
  <div class="kompetenzen-list">
    <div class="kompetenzen-item" v-for="(kompetenz, index) in kompetenzen" :key="index">
      <div class="flex-container">
        <div class="kompetenz-titles">
          <h1>{{kompetenz.title}}</h1>
          <h2>{{kompetenz.subtitle}}</h2>
        </div>
        <div class="kompetenz-text">
          <p>{{kompetenz.text}}</p>
        </div>
      </div>

      <hr v-if="index < kompetenzen.length - 1" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TitleTextList',
  data() {
    return {
      kompetenzen: [
        { title: 'Maßgeschneiderte Anwendungsentwicklung', subtitle: 'Effiziente Lösungen für bestehende Systeme und neue Projekte – Wir machen es möglich.', text: 'Mit unserer langjährigen Erfahrung in der Anwendungsentwicklung stehen wir Ihnen kompetent zur Seite. Ob es um die Transformation und Erweiterung eines bestehenden Systems oder um ein neues Greenfield-Projekt geht, bei dem die Infrastruktur erst noch erschlossen werden muss – wir verfügen über das notwendige Know-how und die Expertise, um Ihre Anforderungen erfolgreich umzusetzen'},
        { title: 'Optimierte Datenprozesse für Ihren Erfolg', subtitle: 'Von der individuellen Datenmodellierung bis zur sicheren Datenmigration', text: 'Wir entwickeln maßgeschneiderte Datenmodelle, die genau auf die Prozesse und Bedürfnisse Ihres Unternehmens abgestimmt sind. Auch bei der Datentransformation zwischen verschiedenen Systemen unterstützen wir Sie, um Ihre Abläufe zu optimieren und fehleranfällige manuelle Arbeit zu eliminieren. Die Ablösung großer Excel-Tabellen durch gut strukturierte Datenbanken macht Ihre Vorgänge effizienter und sicherer.' },
        { title: 'Flexible Unterstützung in Peak-Zeiten', subtitle: 'Erweitern Sie Ihr Software-Team mit erfahrenen Experten und innovativen Ideen', text: 'Braucht Ihr Software-Team Unterstützung in der Crunch Time? Stehen viele Feature-Requests an, fallen wichtige Teammitglieder aus oder gibt es einfach nur viel zu tun? Wir arbeiten gerne mit Ihnen zusammen, ergänzen Ihr Team auf Augenhöhe und bringen dabei frische Ideen und jede Menge Erfahrung mit.' },
     //   { title: 'Title 1', subtitle: 'Subtitle 1', text: '' },
      ]
    };
  }
};
</script>
<style scoped>
.kompetenz-titles,
.kompetenz-text {
  flex: 1;
  /* Optionally, add any additional styling */
}
</style>