<template>
  <button :class="['highlight-button', highlightType]" @click="handleClick">{{ buttonText }}</button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    highlightType: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary'].includes(value)
    },
    clickHandler: {
      type: Function,
      required: true
    }
  },
  methods: {
    handleClick() {
      if (this.clickHandler && typeof this.clickHandler === 'function') {
        this.clickHandler();
      }
    }
  }
};
</script>

<style lang="scss">
@use '../assets/styles/_variables.scss' as vars;

.highlight-button {
  padding: 10px 20px;
  margin: 30px;
  background-color: vars.$primary-color;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  transition: background-color 0.3s ease;
}

.highlight-button:hover {
  background-color: vars.$secondary-color;
}
</style>