<template>
  <main>
    <header class="flex-container">
      <img src="@/assets/images/logo/logo-full.gif" alt="Right Side Image" class="logo-top" />
      <ButtonComponent class="button-component" :click-handler="sendEmail" button-text="Project planen"></ButtonComponent>
    </header>
    <div>
      <AnimatedTextTyping text="Wir schmieden Ihre Software"/>
    </div>
    <div class="flex-container">
      <div class="animated-textview">
        <p>Wir sind die Experten für maßgeschneiderte Web- und Softwarelösungen. Von der Idee bis zur Umsetzung – unser Full-Stack-Team begleitet Sie auf jedem Schritt. Verlässliche Technologie, transparente Prozesse und ein engagiertes Team für Ihren digitalen Erfolg.</p>
      </div>
      <ImageSlideAnimation/>
    </div>
    <AnimatedTextTyping text="Technologisches Know-How, gepaart mit agilem Mindset" />
    <TitleTextList/>
    <div>
      <TeamGallery />
    </div>
  </main>
</template>

<script>
import TeamGallery from "@/components/TeamGallery.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AnimatedTextTyping from "@/components/AnimatedTextTyping.vue";
import './assets/styles/main.scss';
import ImageSlideAnimation from "@/components/ImageSlideAnimation.vue";
import TitleTextList from "@/components/TitleTextList.vue";
export default {
  name: 'App',
  components: {
    TitleTextList,
    ImageSlideAnimation,
    AnimatedTextTyping,
    ButtonComponent,
    TeamGallery,
  },
  methods: {
    sendEmail() {
      window.location.href = "mailto:info@aachener-software-schmiede.de";
    }
  }
}
</script>
<style>
</style>